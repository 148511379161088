import deepFreeze from 'deep-freeze';

import StateAddons, { APIAddon, AddonApp } from '../../interfaces/Addons';

import { ActionInterface } from '../../helpers/actionBuilder';
import { Addons } from '../actions';

export const initialState: StateAddons = {
  error: '',
  fetching: false,
  fetchingAddonConfig: false,
  addons: [],
  addonConfigs: {},
};

const clear = (state: StateAddons = initialState): StateAddons => {
  return {
    ...state,
    addons: [],
    addonConfigs: {},
  };
};

const fetching = (state: StateAddons = initialState): StateAddons => {
  return {
    ...state,
    fetching: true,
  };
};

const receive = (
  state: StateAddons = initialState,
  addons: APIAddon[] = [],
): StateAddons => {
  return {
    ...state,
    addons,
  };
};

const receiveAddonConfig = (
  state: StateAddons = initialState,
  app: AddonApp,
  config: any,
) => {
  return {
    ...state,
    addonConfigs: {
      ...state.addonConfigs,
      [app]: config,
    },
  };
};

const fetchingDone = (state: StateAddons = initialState): StateAddons => {
  return {
    ...state,
    fetching: false,
  };
};

const fetchingAddonConfig = (
  state: StateAddons = initialState,
): StateAddons => {
  return {
    ...state,
    fetchingAddonConfig: true,
  };
};

const fetchingAddonConfigDone = (
  state: StateAddons = initialState,
): StateAddons => {
  return {
    ...state,
    fetchingAddonConfig: false,
  };
};

export default (
  state: StateAddons = initialState,
  action?: ActionInterface,
): StateAddons => {
  if (process.env.NODE_ENV !== 'production') {
    deepFreeze(state);
  }

  if (!action) {
    return { ...{}, ...state };
  }

  switch (action.type) {
    case Addons.clear:
      return clear(state);

    case Addons.fetching:
      return fetching(state);

    case Addons.receive:
      return receive(state, action.payload.addons);
    case Addons.fetchingDone:
      return fetchingDone(state);
    case Addons.fetchingAddonConfig:
      return fetchingAddonConfig(state);
    case Addons.fetchingAddonConfigDone:
      return fetchingAddonConfigDone(state);
    case Addons.receiveConfig:
      return receiveAddonConfig(
        state,
        action.payload.app,
        action.payload.config,
      );
    default:
      return state;
  }
};
