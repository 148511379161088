import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import addons from './addons/reducers';
import app from './app/reducers';
import areaCodes from './areaCodes/reducers';
import bargegroups from './bargegroups/reducers';
import billing from './billing/reducers';
import blockingrules from './blockingrules/reducers';
import branding from './branding/reducers';
import callbundles from './callbundles/reducers';
import callcharging from './callcharging/reducers';
import callrecordings from './callrecordings/reducers';
import countries from './countries/reducers';
import creditstatus from './creditstatus/reducers';
import customers from './customers/reducers';
import forwardingrules from './forwardingrules/reducers';
import groups from './groups/reducers';
import ivrs from './ivrs/reducers';
import limits from './limits/reducers';
import linkeduser from './linkeduser/reducers';
import mailboxes from './mailboxes/reducers';
import numbering from './numbering/reducers';
import permissions from './permissions/reducers';
import phonebook from './phonebook/reducers';
import phoneextensions from './phoneextensions/reducers';
import phonenumbers from './phonenumbers/reducers';
import preferences from './preferences/reducers';
import pricing from './pricing/reducers';
import provisioning from './provisioning/reducers';
import queues from './queues/reducers';
import registrations from './registrations/reducers';
import routingrules from './routingrules/reducers';
import sounds from './sounds/reducers';
import timeintervals from './timeintervals/reducers';
import toasts from './toasts/reducers';
import trunks from './trunks/reducers';
import user from './user/reducers';
import virtuals from './virtuals/reducers';

const rootReducer = (history: History) =>
  combineReducers({
    app,
    areaCodes,
    bargegroups,
    billing,
    blockingrules,
    branding,
    callbundles,
    callcharging,
    callrecordings,
    countries,
    creditstatus,
    customers,
    forwardingrules,
    groups,
    ivrs,
    limits,
    linkeduser,
    mailboxes,
    numbering,
    permissions,
    phonebook,
    phoneextensions,
    phonenumbers,
    preferences,
    pricing,
    provisioning,
    queues,
    registrations,
    router: connectRouter(history),
    routingrules,
    sounds,
    timeintervals,
    toasts,
    trunks,
    user,
    virtuals,
    addons,
  });

export default rootReducer;
