import { ActionInterface, SuccessAction } from '../../helpers/actionBuilder';

import * as toastActions from '../toasts/actions';

import {
  createMsg,
  fetchMsg,
  removeMsg,
} from '../../helpers/actionMessageCreator';
import { nvFetch } from '../../helpers/nvFetch';
import { APIAddon } from '../../interfaces/Addons';
import { APITeamsOCConfig } from '../../interfaces/Addons/TeamsOC';
import State from '../../interfaces/State';
import Things from '../../interfaces/Things';
import { accountConfigSelector } from '../../selectors/customer';
import { Addons } from '../actions';

export const clear = (): ActionInterface => {
  return new SuccessAction(Addons.clear).json;
};

export const getAddons = (): any => {
  return async (
    dispatch: (Action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(Addons.fetching).json);
    const { activeCustomerId, accessToken } = getState().user;
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/addons`,
        accessToken,
      )) as Things<APIAddon>;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: fetchMsg(terms.addon.other, true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(Addons.fetchingDone).json);
      return;
    }

    if (!data) {
      dispatch(new SuccessAction(Addons.fetchingDone).json);
      return;
    }

    dispatch(new SuccessAction(Addons.fetchingAddonConfig).json);
    for (const addon of data.items || []) {
      if (addon.app === 'teamsoc') {
        try {
          const addonData = (await nvFetch(
            `customers/${activeCustomerId}/addons/teamsoc`,
            accessToken,
          )) as APITeamsOCConfig;
          dispatch(
            new SuccessAction(Addons.receiveConfig, {
              app: 'teamsoc',
              config: addonData,
            }).json,
          );
        } catch (error) {
          dispatch(
            toastActions.push({
              delay: 10000,
              message: fetchMsg(terms.addon.other, true, error.message),
              type: 'danger',
            }),
          );
        }
      }
    }
    dispatch(new SuccessAction(Addons.fetchingAddonConfigDone).json);

    dispatch(new SuccessAction(Addons.fetchingDone).json);

    dispatch(
      new SuccessAction(Addons.receive, {
        addons: data.items || [],
      }).json,
    );
  };
};

export const deleteConfig = (appName: string): any => {
  return async (
    dispatch: (Action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(Addons.fetchingAddonConfig).json);
    const { activeCustomerId, accessToken } = getState().user;
    try {
      await nvFetch(
        `customers/${activeCustomerId}/addons/${appName}`,
        accessToken,
        'DELETE',
      );
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: removeMsg(terms.addonConfig.other, true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(Addons.fetchingAddonConfigDone).json);
      return;
    }
    dispatch(new SuccessAction(Addons.fetchingDone).json);

    dispatch(
      new SuccessAction(Addons.receiveConfig, {
        // FIXME should not be undefined but not sure if the DELETE request returns anything
        app: appName,
        config: undefined,
      }).json,
    );
  };
};

export const createConfig = (
  appName: string,
  params: Record<string, string>,
): any => {
  return async (
    dispatch: (Action: ActionInterface) => void,
    getState: () => State,
  ) => {
    const { terms } = accountConfigSelector(getState());
    dispatch(new SuccessAction(Addons.fetchingAddonConfig).json);
    const { activeCustomerId, accessToken } = getState().user;
    let data;
    try {
      data = (await nvFetch(
        `customers/${activeCustomerId}/addons/${appName}`,
        accessToken,
        'POST',
        { type: 'teamsoc', ...params },
      )) as APITeamsOCConfig;
    } catch (error) {
      dispatch(
        toastActions.push({
          delay: 10000,
          message: createMsg(terms.addonConfig.other, true, error.message),
          type: 'danger',
        }),
      );
      dispatch(new SuccessAction(Addons.fetchingAddonConfigDone).json);
      return;
    }
    dispatch(new SuccessAction(Addons.fetchingAddonConfigDone).json);

    dispatch(
      new SuccessAction(Addons.receiveConfig, {
        // FIXME should not be undefined but not sure if the DELETE request returns anything
        app: appName,
        config: data,
      }).json,
    );
  };
};
