import Thing from '../interfaces/Thing';
import Things from '../interfaces/Things';
import { X_PARTNER_ID } from './xPartnerId';

type NvFetch<T> = (
  resource: string,
  accessToken?: string,
  method?: 'POST' | 'GET' | 'PUT' | 'DELETE',
  body?: any,
  customHeaders?: CustomHeaders,
) => Promise<T>;

interface CustomHeaders extends Partial<Headers> {
  Accept?: string;
  'Access-Control-Allow-Origin'?: string;
  Authorization?: string;
  'Content-Type'?: string;
  'X-Partner-Id'?: string;
  'X-Relationship-Key': string;
  'X-WWW-Authenticate'?: string;
  'X-Content-Transfer-Encoding': string;
}

const doFetch = (
  resource = '',
  options = {},
  resolve: (data: any) => void,
  reject: (error: Error) => void,
) => {
  fetch(resource, options)
    .then((response: Response) => {
      if (response.status < 400) {
        if (response.status === 204) {
          return response.text();
        }

        return response.json();
      }

      return response
        .json()
        .then(reject)
        .catch(() => {
          reject(new Error(`${response.status}: ${response.statusText}`));
        });
    })
    .then(resolve)
    .catch(reject);
};

const _getNvFetch = <T>(
  apiBase: string,
  loginRedirectUrl?: string,
): NvFetch<T> => (
  resource: string,
  accessToken?: string,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'GET',
  body?: any,
  customHeaders?: CustomHeaders,
) =>
  new Promise((resolve: () => void, reject: (error: Error) => void) => {
    const APIRoot = apiBase;

    // TODO retype this
    const options: any = { method };
    // const options: Partial<Request> = { method };
    const headers: CustomHeaders = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': 'origin',
      'Content-Type': 'application/json',
      'X-Partner-Id': X_PARTNER_ID.get(),
      'X-Relationship-Key': 'id',
      'X-WWW-Authenticate': 'false',
      ...customHeaders,
    };

    if (accessToken && !loginRedirectUrl) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    // Unset headers which are passed as undefined
    for (const key in customHeaders) {
      if (customHeaders[key] === undefined) {
        delete headers[key];
      }
    }

    options.headers = headers;

    if (method === 'PUT' || method === 'POST') {
      if (body) {
        options.body = body;

        if (!(body instanceof FormData)) {
          try {
            options.body = JSON.stringify(body);
          } catch (error) {
            console.error(error); // ¯\_(ツ)_/¯
          }
        }
      } else {
        return reject(new Error('No body data'));
      }
    }

    const fullResource = `${APIRoot}/${resource}`;

    doFetch(fullResource, options, resolve, reject);
  });

/**
 * Fetch something from Nimvelo API
 * Provides all necessary headers
 *
 * @param resource E.g. 'customers/5'
 * @param accessToken Encoded JWT
 * @param method POST, GET, PUT, DELETE
 * @param body JSON data. Ignored unless method is PUT or POST
 * @param customHeaders Additional request headers
 * @return Thing or Things PBX API response
 */
let nvFetch: NvFetch<Thing | Things<any>> = _getNvFetch(
  process.env.REACT_APP_PBX_API,
);

// TODO ugly, better way?
export const bindNvFetch = (apiBase: string, loginRedirectUrl?: string) => {
  nvFetch = _getNvFetch(apiBase);
};

export { nvFetch };
