import { AccountType } from '../interfaces/Customer';

const managerTerms = {
  phoneNumber: { one: 'phone number', other: 'phone numbers' },
  endpoint: { one: 'endpoint', other: 'endpoints' },
  phone: { one: 'phone extension', other: 'phone extensions' },
  virtual: { one: 'virtual extension', other: 'virtual extensions' },
  group: { one: 'ring group', other: 'ring groups' },
  queue: { one: 'queue', other: 'queues' },
  ivr: { one: 'IVR menu', other: 'IVR menus' },
  mailbox: { one: 'mailbox', other: 'mailboxes' },
  trunk: { one: 'trunk', other: 'trunks' },
  live: 'live',
  account: 'account',
  sound: { one: 'sound', other: 'sounds' },
  soundFile: { one: 'sound file', other: 'sounds files' },
  timeInterval: { one: 'time interval', other: 'time intervals' },
  callRecording: { one: 'call recording', other: 'call recordings' },
  phonebook: { one: 'phonebook', other: 'phonebooks' },
  outgoingCallerId: { one: 'outgoing caller ID', other: 'outgoing caller IDs' },
  contact: 'contact',
  credit: 'credit',
  callBundle: { one: 'call bundle', other: 'call bundles' },
  callHistory: 'call history',
  smsHistory: 'SMS history',
  user: { one: 'user', other: 'users' },
  customerContact: 'primary contact',
  copyright: '© %@1 all rights reserved',
  bargegroup: { one: 'barge group', other: 'barge groups' },
  team: { one: 'team member', other: 'team' },
  departmentOrTeam: { one: 'a particular team or department' },
  blockingRules: { one: 'blocked caller', other: 'blocked callers' },
  forwardingRule: { one: 'forwarding rule', other: 'forwarding rules' },
  addon: { one: 'addon', other: 'addons' },
  addonConfig: { one: 'addon config', other: 'addon configs' },
};

const managerTitles = {
  phonebook: {
    one: 'Phonebook',
    other: 'Phonebook',
  },

  phoneNumbers: {
    one: 'Incoming Number',
    other: 'Incoming Numbers',
    routingRules: {
      link: 'Routing Rules',
      title: 'Routing Rules',
      during: 'During',
      sendCallsTo: 'Send calls to',
      noRules: 'No time-based rules configured',
    },
    welcomeSound: {
      link: 'Welcome Sound',
      title: 'Welcome Sound',
      upload: 'Upload',
    },
    smsSettings: {
      link: 'SMS Settings',
      title: 'SMS Settings',
      allowIncoming: 'Allow incoming',
      notificationEmail: 'Notification email',
    },
  },

  extensions: {
    one: 'Extension',
    other: 'Extensions',
  },

  groups: {
    one: 'Ring Group',
    other: 'Ring Groups',
  },

  queues: {
    one: 'Call Queue',
    other: 'Call Queues',
  },

  ivrs: {
    one: 'IVR Menu',
    other: 'IVR Menus',
  },

  mailboxes: {
    one: 'Shared Mailbox',
    other: 'Shared Mailboxes',
  },

  trunks: {
    one: 'Trunk',
    other: 'Trunks',
  },

  addons: {
    one: 'Addon',
    other: 'Addons',
  },

  sounds: {
    one: 'Sound File',
    other: 'Sound Files',
  },
  prompts: {
    one: 'Announcement',
    other: 'Announcements',
  },

  musics: {
    one: 'Hold Music',
    other: 'Hold Music',
  },
  billing: {
    one: 'Billing',
    other: 'Billing',
  },

  callHistory: {
    one: 'Call History',
    other: 'Call History',
  },

  callRecordings: {
    one: 'Call Recording',
    other: 'Call Recordings',
  },

  contact: {
    one: 'Contact',
    other: 'Contact',
  },

  credit: {
    one: 'Credit & Bundles',
    other: 'Credit & Bundles',
  },

  smsHistory: {
    one: 'SMS History',
    other: 'SMS History',
  },

  team: {
    one: 'Team Member',
    other: 'Team',
  },

  timeIntervals: {
    one: 'Time Interval',
    other: 'Time Intervals',
  },

  user: {
    one: 'User',
    other: 'Users',
  },

  bargegroups: {
    one: 'Barge Group',
    other: 'Call Barge Groups',
  },
  blockingRule: {
    one: 'Blocked Caller',
    other: 'Blocked Callers',
  },

  companyName: { one: 'Company' },

  invoices: {
    one: 'Invoice',
    other: 'Invoices',
  },
};

// FIXME-RES typing
export type AccountTerms = typeof managerTerms;
export type AccountTitles = typeof managerTitles;

const residentialTerms: AccountTerms = {
  ...managerTerms,
  ivr: { one: 'phone tree', other: 'phone trees' },
  team: { one: 'family member', other: 'family members' },
  departmentOrTeam: { one: 'multiple family members' },
};

const residentialTitles: AccountTitles = {
  ...managerTitles,
  companyName: { one: 'Account Name' },
  ivrs: { one: 'Phone Tree', other: 'Phone Trees' },
  phoneNumbers: {
    ...managerTitles.phoneNumbers,
    one: 'Phone Number',
    other: 'Phone Numbers',
  },
  team: { one: 'Family Member', other: 'Family Members' },
  invoices: {
    one: 'Bill',
    other: 'Bills',
  },
};

export const terms: { [key in AccountType]: AccountTerms } = {
  BUSINESS: managerTerms,
  RESIDENTIAL: residentialTerms,
  UNKNOWN: managerTerms,
  WLR: residentialTerms,
};

export const titles: { [key in AccountType]: AccountTitles } = {
  BUSINESS: managerTitles,
  RESIDENTIAL: residentialTitles,
  UNKNOWN: managerTitles,
  WLR: residentialTitles,
};
